import React from "react";
import styled, { css } from "styled-components";
import { fadeIn } from "./fadeIn";

const Wrapper = styled.h1`
  .char {
    opacity: 0;
  }
`;

const AnimatedHeading = ({ className }) => {
  return (
    <Wrapper className={className}>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.1s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        A
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.13s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        r
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.16s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        e
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.19s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.22s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        y
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.25s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        o
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.28s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        u
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.31s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.34s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        l
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.37s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        o
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.4s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        o
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.43s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        k
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.46s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        i
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.49s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        n
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.52s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        g
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.55s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.58s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        f
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.61s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        o
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.64s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        r
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.67s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.7s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        a
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.73s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        n
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 0.76s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span className="break">
        <strong>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.79s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            i
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.82s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            m
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.85s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            p
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.88s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            a
          </span>

          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.91s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            c
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.94s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            t
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 0.97s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            f
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 1s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            u
          </span>
          <span
            className="char"
            css={css`
              animation: ${fadeIn} 0.2s 1.03s
                cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
            `}
          >
            l
          </span>
        </strong>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.06s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          {" "}
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.09s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          b
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.12s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          r
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.15s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          a
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.18s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          n
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.21s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          d
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.24s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          {" "}
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.27s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          e
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.3s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          x
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.33s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          p
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.36s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          e
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.39s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          r
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.42s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          i
        </span>

        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.45s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          e
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.48s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          n
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.51s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          c
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.54s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          e
        </span>
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.57s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.6s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        t
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.63s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        o
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.66s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <strong>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.69s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          g
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.72s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          r
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.75s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          o
        </span>
        <span
          className="char"
          css={css`
            animation: ${fadeIn} 0.2s 1.78s
              cubic-bezier(0.785, 0.135, 0.15, 0.86) forwards;
          `}
        >
          w
        </span>
      </strong>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.81s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.84s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        y
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.87s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        o
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.9s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        u
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.93s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        r
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.96s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        {" "}
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 1.99s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        b
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.02s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        u
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.05s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        s
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.08s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        i
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.11s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        n
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.14s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        e
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.17s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        s
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.2s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        s
      </span>
      <span
        className="char"
        css={css`
          animation: ${fadeIn} 0.2s 2.23s cubic-bezier(0.785, 0.135, 0.15, 0.86)
            forwards;
        `}
      >
        ?
      </span>
    </Wrapper>
  );
};

export default AnimatedHeading;
