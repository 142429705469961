import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  graphik,
  larsseit,
  mainWhite,
  mainBlack,
  screen,
} from "../../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  background-color: ${mainWhite};

  padding-top: 50px;
  @media ${screen.xsmall} {
    display: none;
  }

  .text {
    padding: 0 26px;
    &__heading {
      color: ${mainBlack};
      font-family: ${larsseit};
      font-size: 2.3rem;
      line-height: 1.17;
      margin-bottom: 20px;
      @media ${screen.xsmall} {
        margin-bottom: 0;
      }
      @media ${screen.small} {
        font-size: 2.9rem;
      }
      @media ${screen.medium} {
        font-size: calc(0.9rem + 2vw);
      }
    }

    &__description {
      color: ${mainBlack};
      font-family: ${graphik};
      font-size: 1.1rem;
      margin-bottom: 50px;
      @media ${screen.small} {
        font-size: 1.15rem;
        margin-bottom: 50px;
      }
      @media ${screen.medium} {
        font-size: calc(0.3rem + 1vw);
        margin-bottom: 50px;
      }
    }
  }

  .client-list {
    background-color: ${mainWhite};
    display: flex;
    flex-wrap: wrap;
    margin: 0 5px;
    padding-bottom: 5px;

    &__item {
      width: calc(50% - 10px);
      margin: 5px;
      background: #f7f7f7;
    }
  }
`;

// shuffle array items
const shuffle = (a) => {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
};

const ClientSection = ({ client_list, client_heading, client_description }) => {
  const [randomizedClientList, setRandomizedClientList] = useState([]);
  // sliced to 8 items for render

  useEffect(() => {
    setRandomizedClientList(shuffle(client_list));
  }, []);

  return (
    <Wrapper>
      <div className="text">
        <h2
          className="text__heading"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          {client_heading}
        </h2>
        <p
          className="text__description"
          data-sal="slide-up"
          data-sal-duration="500"
        >
          {client_description}
        </p>
      </div>
      <ul className="client-list" data-sal="slide-up" data-sal-duration="500">
        {randomizedClientList.slice(0, 8).map((item, i) => (
          <li className="client-list__item" key={i}>
            <a
              href={item.website_url}
              target="_blank"
              rel="noopener noreferrer"
            >
              {item.logo.gatsbyImageData && (
                <GatsbyImage
                  image={item.logo.gatsbyImageData}
                  alt={item.logo.alt || "Client Brand"}
                />
              )}
            </a>
          </li>
        ))}
      </ul>
    </Wrapper>
  );
};

export default ClientSection;
