import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import {
  graphik,
  larsseit,
  mainWhite,
  mainBlack,
  transHover,
  screen,
} from "../../components/common/variables";
import { GatsbyImage } from "gatsby-plugin-image";

const Wrapper = styled.section`
  display: none;
  @media ${screen.xsmall} {
    display: block;
    background: ${mainWhite};
    padding: 60px 0;
  }
  @media ${screen.small} {
    padding: 90px 0;
  }
  @media ${screen.medium} {
    padding: 90px 0;
  }
  @media ${screen.xlarge} {
    padding: 120px 0;
  }

  .text {
    @media ${screen.xsmall} {
      padding: 0 60px;
    }
    @media ${screen.small} {
      padding: 0 80px;
    }
    @media ${screen.medium} {
      padding: 0 11vw;
    }
    @media ${screen.xlarge} {
      padding: 0 11vw;
    }

    &__heading {
      color: ${mainBlack};
      font-family: ${larsseit};
      line-height: 1.17;
      font-size: 2.3rem;
      margin-bottom: 20px;
      @media ${screen.small} {
        font-size: 2.9rem;
        max-width: 750px;
      }
      @media ${screen.medium} {
        font-size: 3rem;
      }
      @media ${screen.xlarge} {
        margin-bottom: 30px;
      }
    }

    &__description {
      font-size: 1.1rem;
      font-family: ${graphik};
      max-width: 770px;
      margin-bottom: 40px;
      @media ${screen.small} {
        font-size: 1.15rem;
      }
      @media ${screen.medium} {
        font-size: 1.33rem;
      }
      @media ${screen.xlarge} {
        margin-bottom: 60px;
      }
    }
  }

  .carousel {
    margin: 0 -180px;
    .slick-slider {
      .slick-list {
        .slick-track {
          .slick-slide {
            .each-client {
              background: #f7f7f7;
              border: 4px ${mainWhite} solid;
              @media ${screen.medium} {
                border: 6px ${mainWhite} solid;
                outline: none;
              }

              a {
                display: block;
                transition: ${transHover};
                @media ${screen.withCursor} {
                  &:hover {
                    opacity: 0.7;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

const settings = {
  dots: false,
  draggable: false,
  infinite: true,
  speed: 4000,
  slidesToShow: 7,
  slidesToScroll: 1,
  centerMode: true,
  autoplay: true,
  autoplaySpeed: 4000,
  arrows: false,
  pauseOnHover: false,
  cssEase: "linear",
};

const ClientSection = ({ client_list, client_heading, client_description }) => {
  return (
    <Wrapper>
      <div className="text" data-sal="slide-up" data-sal-duration="500">
        <h2 className="text__heading">{client_heading}</h2>
        <p className="text__description">{client_description}</p>
      </div>
      <div className="carousel" data-sal="slide-up" data-sal-duration="500">
        <Slider {...settings}>
          {client_list.map((item, i) => (
            <div className="each-client" key={i}>
              <a
                href={item.website_url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit company website"
              >
                {item.logo.gatsbyImageData && (
                  <GatsbyImage
                    image={item.logo.gatsbyImageData}
                    alt={item.logo.alt || "Client Brand"}
                  />
                )}
              </a>
            </div>
          ))}
        </Slider>
        <Slider {...settings} rtl={true}>
          {client_list.map((item, i) => (
            <div className="each-client" key={i}>
              <a
                href={item.website_url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Visit company website"
              >
                {item.logo.gatsbyImageData && (
                  <GatsbyImage
                    image={item.logo.gatsbyImageData}
                    alt={item.logo.alt || "Client Brand"}
                  />
                )}
              </a>
            </div>
          ))}
        </Slider>
      </div>
    </Wrapper>
  );
};

export default ClientSection;
